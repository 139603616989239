<template>
  <right-content>
    <div class="content">
      <div ref="tabs" class="tabs">
        <div id="one" class="active">批量创建</div>
        <div id="two">单个创建</div>
      </div>
      <div class="tab-content">
        <div class="page-content" v-if="flag">
          <upload-mulit :uploadStatus="uploadStatus"></upload-mulit>
        </div>
        <div v-else>
          <slot></slot>
        </div>
      </div>
      <div class="bottom" v-if="flag">
        <h2>导入说明</h2>
        <span>1、导入步骤为：①下载并填写模板。②上传Excel文件。③完成。</span>
        <span>2、请按照导入表格说明来填写对应的信息，必填信息缺失会使导入失败</span>
      </div>
    </div>
  </right-content>
</template>

<script>
import UploadMulit from "./UploadMulit";
import RightContent from "@/views/commonViews/RightContent";
import AddPerson from "@/views/supervision/member-manager/addPerson";

export default {
  components: { UploadMulit, RightContent, AddPerson },
  props: {
    uploadStatus: {
      type: Object
    }
  },
  mounted() {
    const tabs = this.$refs.tabs;
    tabs.childNodes.forEach((node) => {
      node.addEventListener("click", () => {
        //清除样式
        tabs.childNodes.forEach((node) => {
          node.classList.remove("active");
        });
        node.classList.add("active");
        if (node.id == "one") {
          this.flag = true;
        } else {
          this.flag = false;
        }
      });
    });
  },
  data() {
    return {
      activities: ["下载并填写模板", "上传Excel模板", "完成"],
      flag: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    div {
      margin-right: 30px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
      box-sizing: border-box;
      &.active {
        border-bottom: 3px solid red;
      }
    }
  }
  .tab-content {
    flex: 1;
    margin: 80px auto;
  }
  .bottom {
    display: flex;
    flex-direction: column;
    line-height: 25px;
    margin-left: 20px;
  }
}
.el-input__inner {
  border: none !important;
}
.el-input__inner:focus {
  border: none;
}
::v-deep .el-select {
  width: 120px;
}
</style>
