export function addParams(request) {
 let param = []
 for (let [k,v] of Object.entries(request)) {
   let str = k + '=' + v;
   param.push(str)
 }
 return '?' + param.join("&");
}


export async function exportFun(exportFun,params,funName){
  let param = addParams(params)
 const res = await exportFun(params);
  if(res.status === 200){
    let url =`https://cddj.usoftware.net/web/common/${funName}${param}`;
    // let url = `http://crcccq.usoftware.net/web/common/${funName}${param}`
    window.open(url,'_self')
  }
}
