<template>
  <div class="wrapper">
    <div class="one-page">
      <div style="height: 300px">
        <el-steps direction="vertical" :active="status">
          <el-step title="下载模板"></el-step>
          <el-step title="上传模板"></el-step>
          <el-step title="完成"></el-step>
        </el-steps>
      </div>
      <div class="one-page_content">
        <div class="one">
          <el-button style="width: 250px" @click="downTemplate"
            >下载人员录入模板</el-button
          >
        </div>
        <div class="two">
          <div class="input-border">
            <span ref="span">{{ fileName }}</span>
            <el-upload
              ref="upload"
              :action="action"
              :headers='{ Authorization: token, "X-Requested-With": "XMLHttpRequest"}'
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :auto-upload="false"
              :on-change="onChange"
              :on-error="uploadError"
            >
              <el-button slot="trigger" size="small" type="primary"
                >选取文件</el-button
              >
            </el-upload>
          </div>
            <el-button style="padding:0 30px;border-radius: 0 2px 2px 0;margin-left: 20px;border-radius: 4px;" @click="submitUpload">上传</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {addParams} from '@/utils/params'
export default {
  name: "BaseView",
  data() {
    return {
      status: 1,
      token: "",
      fileName:""
    };
  },
  props:{
    uploadStatus:Object
  },
  created() {
    this.token = localStorage.getItem("token");
  },
  computed:{
    action(){
      if(this.uploadStatus.name === 'sentry') {
        return "/web/department/leadSentry"
      }
      if(this.uploadStatus.name === 'users') {
        return `/web/department/leadUser${addParams(this.uploadStatus.request)}`
      }
    }
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    onChange({name}){
      this.fileName = name;
    },
    downTemplate(){
      window.open(`http://cddj.usoftware.net/${this.uploadStatus.name}.xlsx`,"_self")
        setTimeout(() => {
          //等待模板下载几秒
          this.status = 2;
        },3000)
    },
    uploadError(){
      this.$message.error("批量导入失败 请检查文件内容是否有误或重复");
    },
    handleAvatarSuccess() {
      this.status = 3;
      this.$refs.span.innerText=""
      this.$message.success("上传成功");
      setTimeout(() => {
        window.location.reload();
      },1000);
    },
    beforeAvatarUpload(file) {
      if (!file.name.endsWith(".xlsx")) {
        this.$message.error("上传文件格式错误");
        return false;
      }
      this.status = 2;
    },
  },
};
</script>

<style lang="scss" scoped>
.one-page {
  display: flex;
  margin-top: 100px;
  .one-page_content {
    margin-top: -5px;
    margin-left: 50px;
    flex: 1;
    div {
      margin-bottom: 90px;
      position: relative;
      button {
        background-color: #E32322;
        color: #fff;
        border: none;
        height: 40px;

      }
    }
    .two {
      display: flex;
      .input-border {
        display: flex;
        justify-content: space-between;
        width: 250px;
        display: flex;
        border: 1px solid darkgray;
        height: 40px;
        border-radius: 4px;
        overflow: hidden;
        span{
          line-height: 40px;
          margin-left: 10px;
        }
        button {
          height: 40px;
          padding: 0 20px;
          border-radius: 0;
        }
      }
    }
  }
  .bottom {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    line-height: 25px;
  }
}
::v-deep .el-input__inner {
  border: none;
  outline: none;
}
::v-deep .el-upload-list__item-name {
  position: absolute !important;
  left: 0;
  top: 0;
}
</style>
