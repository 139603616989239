<template>
  <left-content>
    <div class="tree">
      <el-tree
        class="filter-tree"
        @node-click="nodeClick"
        :data="treeData"
        default-expand-all
        ref="tree"
      >
      </el-tree>
    </div>
  </left-content>
</template>

<script>
import { getAllChildrenList } from "@/api";
import LeftContent from "./LeftContent";
export default {
  name: "TreeToVliew",
  components: { LeftContent },
  data() {
    return {
      treeData: [
        {
          label: JSON.parse(localStorage.getItem("userInfo")).name,
          children: [],
        },
      ]
    };
  },
  methods: {
    nodeClick(row) {
      if(this.addPerson && row.flag != 'department_id'){
         if(row.id){
           this.$emit("getId", row.id);
         }
         return;
      }
      const { flag, id } = row;
      let requestData = {};
      requestData[flag] = id;
      requestData.page = 1;
      this.$emit("request", requestData);
    },
    async renderTreeList(){
      const res = await getAllChildrenList({ type: 3});
      this.treeData[0].children = res.data.data.sentry.map(item => {
        return {
          label:item.name,
          id:item.id,
          flag:"sentry_id"
        }
      });
    },
  },
  async activated(){
    this.renderTreeList();
  },
  async created() {
    this.renderTreeList();
    const firstParams = {
      flag: "department_id",
      id: localStorage.getItem("department_id"),
    };
    this.nodeClick(firstParams);
  },
  props:{
    addPerson:{
      type:Boolean
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-tree {
  margin: 15px;
  background-color: #ffffff;
  font-size: 20px;
}
::v-deep .el-tree-node__content{
  height:35px;
}

::v-deep .el-tree-node__label{
  font-size: 14px;
}
</style>
